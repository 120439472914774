define("@fleetbase/fleetops-data/utils/geojson/feature-collection", ["exports", "@fleetbase/fleetops-data/utils/geojson/geo-json", "@fleetbase/fleetops-data/utils/geojson/feature", "@ember/array"], function (_exports, _geoJson, _feature, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class FeatureCollection extends _geoJson.default {
    constructor(input) {
      super();
      if (input && input.type === 'FeatureCollection' && input.features) {
        Object.assign(this, input);
      } else if ((0, _array.isArray)(input)) {
        this.features = input;
      } else {
        throw 'GeoJSON: invalid input for new FeatureCollection';
      }
      this.type = 'FeatureCollection';
    }
    forEach(func) {
      for (var i = 0; i < this.features.length; i++) {
        func.apply(this, [this.features[i], i, this.features]);
      }
    }
    get(id) {
      var found;
      this.forEach(function (feature) {
        if (feature.id === id) {
          found = feature;
        }
      });
      return new _feature.default(found);
    }
  }
  _exports.default = FeatureCollection;
});