define("@fleetbase/fleetops-data/utils/geojson/polygon", ["exports", "@fleetbase/fleetops-data/utils/geojson/geo-json", "@fleetbase/fleetops-data/utils/geojson/closed-polygon", "@ember/array"], function (_exports, _geoJson, _closedPolygon, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Polygon extends _geoJson.default {
    constructor(input) {
      super();
      if (input && input.type === 'Polygon' && input.coordinates) {
        Object.assign(this, input);
      } else if ((0, _array.isArray)(input)) {
        this.coordinates = input;
      } else {
        throw 'GeoJSON: invalid input for new Polygon';
      }
      this.type = 'Polygon';
    }
    addVertex(point) {
      this.insertVertex(point, this.coordinates[0].length - 1);
      return this;
    }
    insertVertex(point, index) {
      this.coordinates[0].splice(index, 0, point);
      return this;
    }
    removeVertex(remove) {
      this.coordinates[0].splice(remove, 1);
      return this;
    }
    close() {
      this.coordinates = (0, _closedPolygon.default)(this.coordinates);
    }
    hasHoles() {
      return this.coordinates.length > 1;
    }
    holes() {
      var holes = [];
      if (this.hasHoles()) {
        for (var i = 1; i < this.coordinates.length; i++) {
          holes.push(new Polygon([this.coordinates[i]]));
        }
      }
      return holes;
    }
  }
  _exports.default = Polygon;
});