define("@fleetbase/fleetops-data/utils/geojson/multi-polygon", ["exports", "@fleetbase/fleetops-data/utils/geojson/geo-json", "@fleetbase/fleetops-data/utils/geojson/polygon", "@fleetbase/fleetops-data/utils/geojson/closed-polygon", "@ember/array"], function (_exports, _geoJson, _polygon, _closedPolygon, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MultiPolygon extends _geoJson.default {
    constructor(input) {
      super();
      if (input && input.type === 'MultiPolygon' && input.coordinates) {
        Object.assign(this, input);
      } else if ((0, _array.isArray)(input)) {
        this.coordinates = input;
      } else {
        throw 'GeoJSON: invalid input for new MultiPolygon';
      }
      this.type = 'MultiPolygon';
    }
    forEach(func) {
      for (var i = 0; i < this.coordinates.length; i++) {
        func.apply(this, [this.coordinates[i], i, this.coordinates]);
      }
    }
    get(i) {
      return new _polygon.default(this.coordinates[i]);
    }
    close() {
      var outer = [];
      this.forEach(function (polygon) {
        outer.push((0, _closedPolygon.default)(polygon));
      });
      this.coordinates = outer;
      return this;
    }
  }
  _exports.default = MultiPolygon;
});