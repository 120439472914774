define("@fleetbase/ember-accounting/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.number = _exports.currency = void 0;
  var currency = _exports.currency = {
    symbol: '$',
    // default currency symbol is '$'
    format: '%s%v',
    // controls output: %s = symbol, %v = value (can be object, see docs)
    decimal: '.',
    // decimal point separator
    thousand: ',',
    // thousands separator
    precision: 2,
    // decimal places
    grouping: 3 // digit grouping (not implemented yet)
  };
  var number = _exports.number = {
    precision: 0,
    // default precision on numbers is 0
    grouping: 3,
    // digit grouping (not implemented yet)
    thousand: ',',
    decimal: '.'
  };
});