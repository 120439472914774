define("@fleetbase/fleetops-data/utils/geojson/multi-point", ["exports", "@fleetbase/fleetops-data/utils/geojson/geo-json", "@fleetbase/fleetops-data/utils/geojson/point", "@ember/array"], function (_exports, _geoJson, _point, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class MultiPoint extends _geoJson.default {
    constructor(input) {
      super();
      if (input && input.type === 'MultiPolygon' && input.coordinates) {
        Object.assign(this, input);
      } else if ((0, _array.isArray)(input)) {
        this.coordinates = input;
      } else {
        throw 'GeoJSON: invalid input for new MultiPolygon';
      }
      this.type = 'MultiPolygon';
    }
    forEach(func) {
      for (var i = 0; i < this.coordinates.length; i++) {
        func.apply(this, [this.coordinates[i], i, this.coordinates]);
      }
    }
    addPoint(point) {
      this.coordinates.push(point);
      return this;
    }
    insertPoint(point, index) {
      this.coordinates.splice(index, 0, point);
      return this;
    }
    removePoint(remove) {
      if (typeof remove === 'number') {
        this.coordinates.splice(remove, 1);
      } else {
        this.coordinates.splice(this.coordinates.indexOf(remove), 1);
      }
      return this;
    }
    get(i) {
      return new _point.default(this.coordinates[i]);
    }
  }
  _exports.default = MultiPoint;
});